const CRUD = {
  data: () => ({
    loading: false
  }),
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    }
  },
  mounted() {},
  methods: {
    async save(validate = true) {
      if (validate && !this.$refs.form.validate()) return
      this.loading = true
      this.$eventBus.$emit("loader", true)
      /*if (
        this.Store == "at" &&
        this.MyObject.data &&
        this.MyObject.id_utilizador &&
        Number(this.MyObject.estado) < 2
      )
        this.MyObject.estado = 1
      else*/ if (
        this.Store == "at" &&
        (!this.MyObject.id_utilizador ||
          typeof this.MyObject.id_utilizador == "undefined")
      ) {
        this.MyObject.id_utilizador = null
        this.MyObject.estado = 0
      } else if (this.Store == "at" && !this.MyObject.data) {
        this.MyObject.estado = 0
      }

      const options = await this.CreateOptions()

      this.$store
        .dispatch(options.action, options.object)
        .then(res => {
          if (typeof res == "undefined") return

          if (res) this.$toast.success(this.$t("messages.success"))
          else this.$toast.error(this.$t("messages.error"))

          this.$eventBus.$emit("AddDialog", false)
        })
        .catch(err => {
          this.$toast.error(this.$t("messages.error"))
          this.$eventBus.$emit("AddDialog", false)
        })
        .finally(() => {
          this.loading = false
          this.$eventBus.$emit("loader", false)
        })
    },
    async eliminar() {
      this.loading = true
      this.$eventBus.$emit("loader", true)
      this.$store
        .dispatch(`${this.Store}/delete`, {
          id: this.MyObject[`id_${this.Store}`]
        })
        .then(res => {
          if (typeof res == "undefined") return

          if (res) this.$toast.success(this.$t("messages.success"))
          else this.$toast.error(this.$t("messages.error"))

          this.$eventBus.$emit("DeleteDialog", false)
        })
        .catch(err => {
          this.$toast.error(this.$t("messages.error"))
          this.$eventBus.$emit("DeleteDialog", false)
        })
        .finally(() => {
          this.loading = false
          this.$eventBus.$emit("loader", false)
        })
    },

    UpdateState(state, clear = false) {
      if (!state) return

      if (this.Store === "at" && clear) {
        var options = {
          id: this.MyObject[`id_${this.Store}`],
          estado: state,
          data: "",
          hora: "",
          id_utilizador: null
        }
      } else if (
        this.Store === "at" &&
        state === 1 &&
        (this.MyObject.data === "" || this.MyObject.id_utilizador == null)
      ) {
        this.$toast.error(
          "Não é possível agendar uma assistência sem data ou colaborador!"
        )
        return
      } else {
        var options = {
          id: this.MyObject[`id_${this.Store}`],
          estado: state
        }
      }

      this.$store
        .dispatch(`${this.Store}/${this.Store === "at" ? 'updatestate' : 'update'}`, options)
        .then(res => {
          if (typeof res == "undefined") return

          if (res) this.$toast.success(this.$t("messages.success"))
          else this.$toast.error(this.$t("messages.error"))
        })
        .finally(() => {
          this.loading = false
          this.$eventBus.$emit("loader", false)
        })
    },

    CreateOptions() {
      var options = {}

      if (this.MyIndex > -1) {
        options.action = `${this.Store}/update`
        options.object = Object.assign({}, this.MyObject)
        options.object.id = options.object[`id_${this.Store}`]
        delete options.object[`id_${this.Store}`]

        return options
      } else {
        options.action = `${this.Store}/insert`
        options.object = Object.assign({}, this.MyObject)

        return options
      }
    }
  }
}

export default CRUD
