<template>
  <v-data-table
    class="elevation-1"
    :headers="
      TableHeaders.map(el => {
        el.text = $t(`fields.${el.label}`)
        return el
      })
    "
    :items="TableItems"
    multi-sort
    :search="search"
    :footer-props="{
      itemsPerPageText: $t('messages.itemPerPage'),
      itemsPerPageOptions: [10, 25, 50, -1],
      itemPerPageAllText: $t('messages.all')
    }"
    :no-data-text="$t('messages.datatableNoDataText')"
    :no-results-text="$t('messages.datatableNoSearchText')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title class="primaryText">{{ TableTitle }}</v-toolbar-title>
        <v-divider v-if="Store != 'cliente'" class="mx-4" inset vertical></v-divider>

        <AddDialog
          v-if="Store != 'cliente'"
          :DialogTitle="formTitle()"
          :FormFields="FormFields"
          :MyObject="MyObject"
          :MyIndex="MyIndex"
          :ModalNewText="ModalNewText"
          :Store="Store"
          :Store2="Store2"
        />

        <DeleteDialog
          :MyObject="MyObject"
          :MyIndex="MyIndex"
          :Store="Store"
          :ModalDeleteText="ModalDeleteText"
          :DeleteDialogTitle="DeleteDialogTitle"
        />

        <LinhaDialog
          v-if="MyIndex > -1 && MyObject.orcamento"
          :MyObject="MyObject"
          :Title="`${MyObject.nome} / ${MyObject.orcamento}`"
          :Items="LINHAS"
        />

        <OrcamentoDialog v-if="MyIndex > -1" :MyObject="MyObject" :Items="ORCAMENTOS" />

        <Intervencao />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :color="$primary"
          :label="$t('actions.search')"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.estado="{ item }">
      <v-menu
        v-if="typeof getStates != 'undefined'"
        bottom
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on: menu }">
          <v-icon
            v-on="{ ...menu }"
            :color="getStates.find(el=>{return el.estado==item.estado}).color"
          >{{ getStates.find(el=>{return el.estado==item.estado}).icon }}</v-icon>
        </template>
        <v-list>
          <v-list-item
            v-bind:class="[
              item.estado == state.estado ? getStates.find(el=>{return el.estado==item.estado}).classe : ''
            ]"
            v-for="(state, index) in getStates"
            :key="index"
            @click="SetState(state, item)"
          >
            <v-list-item-title>{{ state.nome }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:item.data_agendamento="{ item }">
      <span
        v-bind:class="[new Date(item.data_agendamento) <= new Date(item.data_maximo) ? 'dentro' : item.data_agendamento === 'Sem data' && new Date() >= new Date(item.data_maximo) ? 'fora' : '']"
      >{{ item.data_agendamento }}</span>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        medium
        class="mr-2"
        v-for="(action, idx) in TableActions"
        :key="idx"
        :color="action.color"
        :disabled="Store == 'at' && action.action == 'orcamento' && (item.orcamento == null || item.orcamento === '' || item.orcamento === ' ')"
        @click="ActionExecute(action.action, item)"
      >{{ action.icon }}</v-icon>
    </template>
    <template v-slot:no-data>
      <!-- NO DATA -->
      <v-progress-circular
        v-if="Store == 'cliente' && IsClienteLoading"
        indeterminate
        :color="$primary"
      ></v-progress-circular>
    </template>
  </v-data-table>
</template>

<script>
import CRUD from "@/mixins/_CRUD"
import STATES from "@/mixins/_STATES"
export default {
  props: {
    TableItems: Array,
    TableHeaders: Array,
    FormFields: Array,
    TableActions: Array,
    TableTitle: String,
    ModalNewText: String,
    ModalUpdateText: String,
    Store: String,
    Store2: String,
    ModalDeleteText: String,
    DeleteDialogTitle: String
  },
  mixins: [CRUD, STATES],
  components: {
    AddDialog: () => import("@/components/dialogs/AddDialog"),
    Intervencao: () => import("@/components/dialogs/Intervencao"),
    DeleteDialog: () => import("@/components/dialogs/DeleteDialog"),
    LinhaDialog: () => import("@/components/dialogs/LinhaDialog"),
    OrcamentoDialog: () => import("@/components/dialogs/OrcamentoDialog")
  },
  data: () => ({
    search: "",
    MyIndex: -1,
    MyObject: {},
    MyDefaultObject: {},
    LINHAS: [],
    ORCAMENTOS: []
  }),

  computed: {
    getStates() {
      return this.STATES[this.Store]
    },
    getSession() {
      return this.$store.getters["session/getState"]
    },
    IsClienteLoading() {
      return this.$store.getters["cliente/IsLoading"]
    }
  },

  watch: {},

  created() {
    this.$eventBus.$on("ClearDialog", () => {
      this.ClearDialog()
    })
  },

  methods: {
    formTitle() {
      return this.MyIndex === -1 ? this.ModalNewText : this.ModalUpdateText
    },

    ActionExecute(action, item) {
      if (!action || !item) return

      switch (action) {
        case "edit": {
          this.MyIndex = this.TableItems.indexOf(item)
          this.MyObject = Object.assign({}, item)
          this.$eventBus.$emit("AddDialog", true)
          break
        }

        case "delete": {
          this.MyIndex = this.TableItems.indexOf(item)
          this.MyObject = Object.assign({}, item)
          this.$eventBus.$emit("DeleteDialog", true)
          break
        }

        case "intervencao": {
          if (typeof item.id_at == "undefined") return
          this.$eventBus.$emit("Intervencao", item)
          break
        }

        case "orcamento": {
          this.MyIndex = this.TableItems.indexOf(item)
          this.MyObject = Object.assign({}, item)
          this.LINHAS = []
          this.$eventBus.$emit("loader", true)
          this.$store
            .dispatch("linha/get", {
              url: this.$serviceUrl,
              pwd: this.$servicePwd,
              orcamento: this.MyObject.orcamento
            })
            .then(res => {
              if (res && res.length > 0) this.LINHAS = res
              else this.$toast.error(this.$t("messages.datatableNoDataText"))
              this.$eventBus.$emit("loader", false)
              this.$eventBus.$emit("LinhaDialog", true)
            })
          break
        }

        case "orcamento_cliente": {
          this.MyIndex = this.TableItems.indexOf(item)
          this.MyObject = Object.assign({}, item)
          this.ORCAMENTOS = []
          this.$eventBus.$emit("loader", true)
          this.$store
            .dispatch("orcamento/getByCliente", {
              url: this.$serviceUrl,
              pwd: this.$servicePwd,
              id: this.MyObject.clientecodigo
            })
            .then(res => {
              if (res && res.length > 0) this.ORCAMENTOS = res
              else this.$toast.error(this.$t("messages.datatableNoDataText"))
              this.$eventBus.$emit("loader", false)
              this.$eventBus.$emit("OrcamentoDialog", true)
            })
          break
        }

        default:
          break
      }
    },

    ClearDialog() {
      this.MyObject = Object.assign({}, this.MyDefaultObject)
      this.MyIndex = -1

      this.$forceUpdate()
    },

    SetState(state, item) {
      //item.estado = state.estado

      this.MyIndex = this.TableItems.indexOf(item)
      this.MyObject = Object.assign({}, item)

      setTimeout(() => {
        this.UpdateState(state.estado, state.nome == "Pendente")
      }, 50)
    }
  }
}
</script>

<style>
.v-menu__content {
  border-radius: 0px !important;
  box-shadow: 0 0 10px 0px #00000021 !important;
  -webkit-box-shadow: 0 0 10px 0px #00000021 !important;
}

.v-menu__content .v-list {
  padding: 0 !important;
}

.ativo,
.inativo,
.pendente,
.entregue,
.ativo .v-list-item__title,
.inativo .v-list-item__title,
.pendente .v-list-item__title,
.entregue .v-list-item__title {
  color: #fff !important;
  text-align: center !important;
}

.ativo {
  background-color: #7cb242 !important;
}

.inativo {
  background-color: #ef5350 !important;
}

.pendente {
  background-color: #ffc107 !important;
}

.entregue {
  background-color: #00acc1 !important;
}

.colheita-tabela {
  width: 80% !important;
  margin: 0 auto !important;
  margin-top: 5% !important;
}

.fora {
  color: red !important;
}

.dentro {
  color: green !important;
}
</style>
