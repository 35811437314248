var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.TableHeaders.map(el => {
      el.text = _vm.$t(`fields.${el.label}`)
      return el
    }),"items":_vm.TableItems,"multi-sort":"","search":_vm.search,"footer-props":{
    itemsPerPageText: _vm.$t('messages.itemPerPage'),
    itemsPerPageOptions: [10, 25, 50, -1],
    itemPerPageAllText: _vm.$t('messages.all')
  },"no-data-text":_vm.$t('messages.datatableNoDataText'),"no-results-text":_vm.$t('messages.datatableNoSearchText')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"primaryText"},[_vm._v(_vm._s(_vm.TableTitle))]),(_vm.Store != 'cliente')?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.Store != 'cliente')?_c('AddDialog',{attrs:{"DialogTitle":_vm.formTitle(),"FormFields":_vm.FormFields,"MyObject":_vm.MyObject,"MyIndex":_vm.MyIndex,"ModalNewText":_vm.ModalNewText,"Store":_vm.Store,"Store2":_vm.Store2}}):_vm._e(),_c('DeleteDialog',{attrs:{"MyObject":_vm.MyObject,"MyIndex":_vm.MyIndex,"Store":_vm.Store,"ModalDeleteText":_vm.ModalDeleteText,"DeleteDialogTitle":_vm.DeleteDialogTitle}}),(_vm.MyIndex > -1 && _vm.MyObject.orcamento)?_c('LinhaDialog',{attrs:{"MyObject":_vm.MyObject,"Title":`${_vm.MyObject.nome} / ${_vm.MyObject.orcamento}`,"Items":_vm.LINHAS}}):_vm._e(),(_vm.MyIndex > -1)?_c('OrcamentoDialog',{attrs:{"MyObject":_vm.MyObject,"Items":_vm.ORCAMENTOS}}):_vm._e(),_c('Intervencao'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","color":_vm.$primary,"label":_vm.$t('actions.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.estado",fn:function({ item }){return [(typeof _vm.getStates != 'undefined')?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu }){return [_c('v-icon',_vm._g({attrs:{"color":_vm.getStates.find(el=>{return el.estado==item.estado}).color}},{ ...menu }),[_vm._v(_vm._s(_vm.getStates.find(el=>{return el.estado==item.estado}).icon))])]}}],null,true)},[_c('v-list',_vm._l((_vm.getStates),function(state,index){return _c('v-list-item',{key:index,class:[
            item.estado == state.estado ? _vm.getStates.find(el=>{return el.estado==item.estado}).classe : ''
          ],on:{"click":function($event){return _vm.SetState(state, item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(state.nome))])],1)}),1)],1):_vm._e()]}},{key:"item.data_agendamento",fn:function({ item }){return [_c('span',{class:[new Date(item.data_agendamento) <= new Date(item.data_maximo) ? 'dentro' : item.data_agendamento === 'Sem data' && new Date() >= new Date(item.data_maximo) ? 'fora' : '']},[_vm._v(_vm._s(item.data_agendamento))])]}},{key:"item.action",fn:function({ item }){return _vm._l((_vm.TableActions),function(action,idx){return _c('v-icon',{key:idx,staticClass:"mr-2",attrs:{"medium":"","color":action.color,"disabled":_vm.Store == 'at' && action.action == 'orcamento' && (item.orcamento == null || item.orcamento === '' || item.orcamento === ' ')},on:{"click":function($event){return _vm.ActionExecute(action.action, item)}}},[_vm._v(_vm._s(action.icon))])})}},{key:"no-data",fn:function(){return [(_vm.Store == 'cliente' && _vm.IsClienteLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.$primary}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }